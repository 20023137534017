import {createSlice} from '@reduxjs/toolkit'

export const generalSlice = createSlice({
    name: 'general',
    initialState: {
        title: 'Office of Innovation and Industrial Relations',
        titleSEO : 'Office of Innovation and Industrial Relations',
        submenu : [
            { text: 'EC Innovative Entrepreneurship Program', link: '/innovation-center/eciep', isActive: true},
            { text: 'Innovative Start-Up Fund', link: '/innovation-center/isf', isActive: false},
            { text: 'Entrepreneurship Program Fund', link: '/innovation-center/epf', isActive: false},
            { text: 'Co-working Space', link: '/innovation-center/cws', isActive: false},
            { text: '1-to-1 Consultation Sessions', link: '/innovation-center/1cs', isActive: false},
            { text: 'Workshops', link: '/innovation-center/workshops', isActive: false},
        ]
    },
    reducers: {
        changeTitle: (state, action) => {
            state.title = action.payload

        },
        changeTitleSEO: (state, action) => {
            state.titleSEO = action.payload

        },
        changeSubmenu: (state, action) => {
            state.submenu = action.payload

        },
    }
});

export const {changeTitle,changeTitleSEO, changeSubmenu} = generalSlice.actions;


export const generalSelector = state => state.general;

export default generalSlice.reducer;
