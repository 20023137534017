import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeTitle, changeTitleSEO, changeSubmenu, generalSelector} from "../store/reducers/generalReducer";
import {useSSE} from "use-sse";

const Entrepreneurship101 = () => {

    const generalSettings = useSelector(generalSelector);
    const {title} = generalSettings;
    const dispatch = useDispatch()

    useSSE(()=>{
        dispatch(changeTitle('Innovation at HBKU'))
        dispatch(changeTitleSEO('Office of Innovation and Industrial Relations | Entrepreneurship 101'))
        dispatch(changeSubmenu([
            { text: 'EC Innovative Entrepreneurship Program', link: '/innovation-center/eciep', isActive: false},
            { text: 'Innovative Start-Up Fund', link: '/innovation-center/isf', isActive: false},
            { text: 'Entrepreneurship Program Fund', link: '/innovation-center/epf', isActive: false},
            { text: 'Co-working Space', link: '/innovation-center/cws', isActive: false},
            { text: '1-to-1 Consultation Sessions', link: '/innovation-center/1cs', isActive: true},
            { text: 'Workshops', link: '/innovation-center/workshops', isActive: false},
        ]))
        return Promise.resolve('Office of Innovation and Industrial Relations | Entrepreneurship 101')
    },[])

    useEffect(()=>{
        document.title = 'Office of Innovation and Industrial Relations | Entrepreneurship 101'
    },[])
    return (
        <section class="m-wrap ">
            <div class="container">
                <div class="row">
                    <div class="col-md-32">
                        <h2 class="title-xl m-b-2 text-darkblue">1-to-1 Consultation Sessions</h2>
                        <p>Do you have an innovative idea but you don’t know where and how to start?! </p>
                        <p>Innovation Center is now offering individual consultation sessions for HBKU Innovators and Entrepreuners who are seeking advice and guidance for their innovative ideas. </p>
                        <p>Please <a target="_blank" href="https://hbku.wufoo.com/forms/kmoeqt01h3o402/">Contact</a> to book an appointment.</p>
                        
                    </div>
                    <div class="col-md-14 col-md-offset-2">
                        <div class="l-article">
                            <div class="video-wrap">
                                <img class="" src="/images/img-e101.png" alt="Office of Innovation and Industrial Relations" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Entrepreneurship101;
