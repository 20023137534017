import React from 'react';

const AccordionItem = (props) => {
  return (
    <div className='accordion-content__row'>
        <div className='accordion--date'>{props.item.date}</div>
        <div className='accordion--content-title'>{props.item.contentTitle}</div>
        <div className='accordion--text'>{props.item.content}</div>
    </div>
  );
};

export default AccordionItem;