import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeTitle, changeTitleSEO, changeSubmenu, generalSelector} from "../store/reducers/generalReducer";
import {useSSE} from "use-sse";

const InnovativeStartupFund = () => {

    const generalSettings = useSelector(generalSelector);
    const {title} = generalSettings;
    const dispatch = useDispatch()

    useSSE(()=>{
        dispatch(changeTitle('Innovation at HBKU'))
        dispatch(changeTitleSEO('Office of Innovation and Industrial Relations | Certified Workshops'))
        dispatch(changeSubmenu([
            { text: 'EC Innovative Entrepreneurship Program', link: '/innovation-center/eciep', isActive: false},
            { text: 'Innovative Start-Up Fund', link: '/innovation-center/isf', isActive: true},
            { text: 'Entrepreneurship Program Fund', link: '/innovation-center/epf', isActive: false},
            { text: 'Co-working Space', link: '/innovation-center/cws', isActive: false},
            { text: '1-to-1 Consultation Sessions', link: '/innovation-center/1cs', isActive: false},
            { text: 'Workshops', link: '/innovation-center/workshops', isActive: false},
        ]))
        return Promise.resolve('Office of Innovation and Industrial Relations | HBKU Innovative Start-Up Fund (ISF)')
    },[])

    useEffect(()=>{
        document.title = 'Office of Innovation and Industrial Relations | HBKU Innovative Start-Up Fund (ISF)'
    },[])
    return (
        <section class="m-wrap ">
            <div class="container">
                <div class="row">
                    <div class="col-md-48">
                        <div class="row">
                            <div class="col-md-32">
                                <h2 class="title-xl m-b-2 text-darkblue">HBKU Innovative Start-Up Fund (ISF)</h2>
                                <p>The HBKU Innovative Start-Up Fund (ISF) aims to support HBKU entrepreneurs with
                                    industry-driven innovations, targeted at increasing the local capacity,
                                    responsiveness, and private enterprise.</p>

                                <div class="l-article">
                                    <ul>
                                        <li> Click <a  href="/funds">HERE</a > for more details. </li>
                                        <li> <a  href="/apply">To apply</a > </li>
                                       
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-14 col-md-offset-2">
                                <div class="l-article">
                                    <div class="video-wrap">
                                        <img class="" src="../images/fund-img2.png"
                                             alt="Office of Innovation and Industrial Relations" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InnovativeStartupFund;
