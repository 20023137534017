import React, {useEffect} from "react";

import FundingBlock from "../blocks/FundingBlock";
import {useDispatch, useSelector} from "react-redux";
import {changeTitle, changeTitleSEO, changeSubmenu, generalSelector} from "../store/reducers/generalReducer";
import {useSSE} from "use-sse";

const FundsPage = ()=>{

    const generalSettings = useSelector(generalSelector);
    const {title} = generalSettings;
    const dispatch = useDispatch()

    useSSE(()=>{
        dispatch(changeTitle('Innovation at HBKU'))
        dispatch(changeTitleSEO('Office of Innovation and Industrial Relations | Funds'))
        dispatch(changeSubmenu([]))
        return Promise.resolve('Office of Innovation and Industrial Relations - Funds');
    },[])


    useEffect(()=>{
        document.title = 'Office of Innovation and Industrial Relations | Funds'
    },[])

    return(

        <FundingBlock />
    )
}

export default FundsPage;
