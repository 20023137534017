const FAQData = [
 {
   title: 'Do all team members have to be from HBKU or QF?',
   content : [
     {
        content:"Applicant must be a member of QF community with active status (i.e: staff, faculty, student, scientist, researcher BUT not an alumnus/a), other team members can be from outside HBKU or QF."
     }
   ]
 },
 {
 title: 'Do I have the option of hiring anyone from outside of HBKU or QF?',
 content : [
    {
      content:"Yes. You have this option."
    }
  ]
 },
 {
 title: "Is it permissible to submit a proposal without a team or without plans to form a team in the future? Will not having a team negatively affect the proposal's chances of being selected?",
 content : [
    {
      content:"Yes. You can submit the idea individually and this will not impact your evaluation negatively as  there is no preference for teams over individuals."
    }
  ]
 },
 {
 title: 'Is it compulsory for the idea to fall into one of the preferred priority themes?',
 content : [
    {
      content:"These are strategic priority themes that HBKU and QF are focusing on. If project does not fall within one or more of these thematic areas; at least, it should be based on Qatar Vision 2030 priority themes."
    }
  ]
 },
 {
 title: 'Will the Idea be selected if it is not relevant to my study field?',
 content : [
    {
      content:"Yes. It will be selected subject to passing evaluation stage."
    }
  ]
 },
 {
 title: 'What is the time commitment needed for the capacity building part of the program?',
 content : [
    {
      content:"The time commitment is designed not to affect students or staff main responsibilities. You should receive around 12-14 workshops within 10 weeks. The workshops usually start in the afternoon and each workshop could last between 60-120 min max."
    }
  ]
 }
];

export default FAQData;