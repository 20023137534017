import React, {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {changeTitle, changeTitleSEO, changeSubmenu, generalSelector} from "../store/reducers/generalReducer";
import {useSSE} from "use-sse";

const TechnologiesPage = ()=>{
    const generalSettings = useSelector(generalSelector);
    const {title} = generalSettings;
    const dispatch = useDispatch()

    useSSE(()=>{
        dispatch(changeTitle('Innovation at HBKU'))
        dispatch(changeTitleSEO('Office of Innovation and Industrial Relations | Licensing Portfolio'))
        dispatch(changeSubmenu(''))
        return Promise.resolve('Office of Innovation and Industrial Relations | Licensing Portfolio');
    },[])

    useEffect(() => {
        document.title = 'Office of Innovation and Industrial Relations | Licensing Portfolio'
    }, []);


    return (
        <>
            <section className="m-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-48">
                            <p>HBKU Research & Development Corporation QSTP-LLC is a start-up, pre-revenue company founded in 2022 by Hamad Bin Khalifa University (HBKU) to manage, market, and commercialize home-grown innovative products, technologies, and services through different business models. HBKU R&D Corp. is registered under Qatar Science & Technology Park with as the commercialization arm of HBKU.  </p>
                            <div className="title--info"><h2 className="title-xl m-b-2 text-darkblue ">Licensing Portfolio</h2></div>
                            
                            <div id="flintbox-embed" data-subdomain="qf">
                                <noscript>This site requires javascript to be enabled.</noscript>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            <section className="inner-banner cta-banners p-a-0 m-a-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-48">
                        <div className="mez spotlight">
                            <div className="spotlight__slider">
                                <div className="spotlight__item">
                                    <img src="/images/early.png" height="1024"
                                        width="1920" className="img-responsive" />
                                </div>
                            </div>
                            <div className="spotlight__content">
                                <div className="spotlight__title half">
                                    <h1 className="small">Early Stage Commercialization Opportunities
                                    </h1>
                                    <p>Suggest better design for COAs and update by meeting inventors and upload here. <br /> (Please double check the correction of info with each inventors) </p>
                                    <div className="">
                                        <a target="_blank" href="#" className="l-btn bg-darkblue">Register now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </>
    )
}


export default TechnologiesPage;
