import React, { useState } from 'react';
import AccordionItem from './AccordionItem';

const Accordion = ({title, content}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div className='accordion-title--text'>{title}</div>
        <div className='accordion-title--icon'>{isActive ? '-' : '+'}</div>
        
      </div>
      {isActive && <div className="accordion-content">
       {content.map(item => (
           <>
           <AccordionItem item={item} />
           </>
        ))}
      </div>}
    </div>
  );
};

export default Accordion;