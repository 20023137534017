const MenuItems = [
    {
        title: 'OIIR',
        link: '/'
    },
    {
        title: 'Innovation Center',
        link: '/innovation-center'
    },
    {
        title: 'Innovation Funds Office',
        link: '/funds'
    },
    {
        title: 'R&D Corp',
        link: '/rd-corp',
        load:true
    },
    {
        title: 'Expertise Catalog',
        link: 'http://expertise.hbku.edu.qa/',
        redirect: true
    },
    {
        title: 'News & Announcements',
        link: '/news-announcements'
    },
    {
        title: 'Apply',
        link: '/apply'
    },
    // {
    //     title: 'Overview',
    //     link: '/overview'
    // },
    // {
    //     title: 'Entrepreneurship Program',
    //     link: '/entrepreneurship-program'
    // },
    // {
    //     title: 'Portfolio',
    //     link:'/portfolio'
    // },
    // {
    //     title: 'Winners',
    //     link: '/winners'
    // }
];


export default MenuItems;
