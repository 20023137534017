import React from "react";
import MenuItems from "../data/menu-items";
import MenuItem from "./MenuItem";

const Menu = ({items=MenuItems}) => {
    items = items ? items : [];
    return (
        <div className="menu-tab menu-tab_v3">
            <div className="bg-info-light excellence-tabmenu">
                <div className="container">
                    <div className="menuTab-container">
                        <ul className="nav nav-tabs" id="menuTab" role="tablist">
                            {items.map(item=>(<MenuItem {...item} key={item.title} />))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>


)

}

export default Menu
