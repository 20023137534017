import React from "react";

import FundingItems from "../../data/funding-items";
import Fund from "../../components/Fund";

const InnovationBlock = ({
    fundingItems = FundingItems
}) =>{
    return (
        <section className="m-wrap bg-gray6 " data-appear-top-offset="-200" id="section4">
            <div className="services-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-48">
                            <div className="title--info">
                               <div className="row">
                                <div className="col-md-16">
                                    <h2 className="title-xl m-b-2 text-darkblue " data-id="1">
                                    About </h2>
                                    <p>The HBKU Innovation Center is one of OIIR initiatives towards achieving the vision of strengthening a sustainable innovation and entrepreneurship ecosystem within and outside HBKU. </p>
                                    <p>The Center is focusing to nurture mindset of innovation and entrepreneurship among HBKU and QF members, to support cultivation of innovation and entrepreneurship community within HBKU and beyond, and to help establish HBKU-grown startups. </p>
                                </div>
                                <div className="col-md-30 col-md-offset-2">
                                <img src="/images/about-inno-center.png" height="1024"
                                        width="1920" className="img-responsive" />
                                </div>
                               </div>
                                
                            </div>
                            <div className="m-t-3" data-id="2">
                                <div className="row row-eq-height">
                                    <div className="row row-eq-height">
                                        {fundingItems.map((item,i)=>(<Fund {...item} key={'fund-'+i} />))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InnovationBlock;
