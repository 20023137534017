import React, {useEffect} from "react";
import BenefitsItems from "../data/benefits-items";
import Benefits from "../components/Benefits";
import {useDispatch, useSelector} from "react-redux";
import {changeTitle, changeTitleSEO, changeSubmenu, generalSelector} from "../store/reducers/generalReducer";
import {useSSE} from "use-sse";
import Accordion from "../blocks/Innovation/Accordion";
import AccordionData from "../data/EC-IEP-Accordion";
import FAQData from "../data/FAQ";
const ECIEP = ()=>{

    const generalSettings = useSelector(generalSelector);
    const {title} = generalSettings;

    const dispatch = useDispatch()

    useSSE(()=>{
        dispatch(changeTitle('Innovation at HBKU'))
        dispatch(changeTitleSEO('Office of Innovation and Industrial Relations | Innovation Center'))
        dispatch(changeSubmenu([
            { text: 'EC Innovative Entrepreneurship Program', link: '/innovation-center/eciep', isActive: true},
            { text: 'Innovative Start-Up Fund', link: '/innovation-center/isf', isActive: false},
            { text: 'Entrepreneurship Program Fund', link: '/innovation-center/epf', isActive: false},
            { text: 'Co-working Space', link: '/innovation-center/cws', isActive: false},
            { text: '1-to-1 Consultation Sessions', link: '/innovation-center/1cs', isActive: false},
            { text: 'Workshops', link: '/innovation-center/workshops', isActive: false},
        ]))
        return Promise.resolve('Office of Innovation and Industrial Relations - Innovation Center');
    },[])
    useEffect(()=>{
        document.title = 'Office of Innovation and Industrial Relations | Innovation Center'
    },[]);

    return(
        <>
        <section className="m-wrap bg-gray6" data-appear-top-offset="-200">
        <div className="services-section ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-48">
                        <div className="title--info">
                            <div className="row">
                            <div className="col-md-24">
                                <h2 className="title-xl m-b-2 text-darkblue" data-id="1">
                                The Education City Innovative Entrepreneurship Program </h2>
                                <p>The Education City Innovative Entrepreneurship Program aims to support innovative entrepreneurs in Qatar Foundation, the development of an entrepreneurship ecosystem and the realization of innovative business ideas. This program is designed as a long-term initiative in which entrepreneurs in Qatar Foundation will commercialize their business ideas that address the priority areas within the ?<a href="#appTheme"> Qatar Vision 2030</a>. The shortlisted entrepreneurs will then be offered capacity building training and funding opportunities. </p>
                                <p>The target audience of this program are candidates who have an innovative business idea different from conventional business models and who have an active Education City (EC) affiliation. Priority will be given to women entrepreneurs, students, and researchers in the target audience segment. </p>
                                <p>Applications will be received online via the related web page. </p>
                                <p>This program is run in partnership with Qatar Development Bank (QDB), a leading entity that fosters entrepreneurship and SME development in Qatar.</p>
                            </div>
                            <div className="col-md-22 col-md-offset-2">
                                <img src="/images/about-eciep.png" height="1024"
                                    width="1920" className="img-responsive" />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
        <section className="m-wrap" data-appear-top-offset="-200">
            <div className="services-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-48">
                            <div className="title--info">
                            <h2 className="title-xl m-b-2 text-darkblue" data-id="1">Benefits</h2>
                            </div>
                            <div className="row row-eq-height" data-id="2">
                                <div className="row row-eq-height">
                                    {BenefitsItems.map((item,i)=>(<Benefits {...item} key={'fund-'+i} />))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="m-wrap bg-gray6" data-appear-top-offset="-200">
            <div className="services-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-23">
                            <div className="video-wrap m-b-2">
                                <video muted loop controls poster="/images/innovation-hbku-thumbnail.png">
									<source src="/images/Innovation-HBKU.mp4" type="video/mp4" />
								</video>
							</div>
                        </div>
                        <div className="col-md-23 col-md-offset-2">
                            <div className="video-wrap">
                                <img src="/images/qdb-logo.png" alt="" title="" className="full-width img-responsive" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="m-wrap bg-darkblue text-white" data-appear-top-offset="-200" id="appTheme">
            <div className="services-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-23">
                            <div class="mb-5">
                                <h2 class="title-xl m-b-2 text-darkblue">How to Apply</h2>
                                <p>To apply for the EC Innovative Entrepreneurship Program, interested candidates must submit online application of innovative business idea via related web link. Application should cover the following categories: <strong> Idea Summary, Problems/Challenges, Solution, Market Analysis, Team, Entrepreneurial Experience.</strong></p>
                            </div>
                            <div class="">
                                <h2 class="title-xl m-b-2 text-darkblue">Application Themes</h2>
                                <p>The innovative business idea must address priority areas within the Qatar Vision 2030. It is preferably to address one or more of the following QF strategic priority themes:</p>
                                <ul class="l-sub-list">
                                    <li>Progressive Education</li>
                                    <li>Sustainability</li>
                                    <li>Artificial Intelligence</li>
                                    <li>Precision Medicine</li>
                                    <li>Social Progress</li>
                                </ul>
                            </div>
                            {/* <div class="">
                            <a href="#" target="_blank" rel="noreferrer" class="action-white">HBKU Innovative Start-Up Fund (ISF)</a>
                            <a href="#" target="_blank" rel="noreferrer" class="action-white m-t-3">Education City Innovative Entrepreneurship Program - Cycle 2</a>
                            </div> */}
                        </div>
                        <div className="col-md-23 col-md-offset-2">
                            <div class="mb-5">
                                <h2 class="title-xl m-b-2 text-darkblue">Application Eligibility</h2>
                                <p>In order to consider application for review and evaluation, it must be submitted along with the following supporting documents:</p>
                                <ul class="l-sub-list">
                                    <li>Valid QID (with a valid residency permit for non-Qatari candidates)</li>
                                    <li>Valid QF ID</li>
                                    <li>Two-page resume of qualifications and experience.</li>
                                </ul>
                                <p className="m-t-2">Applications that do not meet eligibility criteria, will not be considered for review. </p>
                            </div>
                            <div class="mb-5">
                                <h2 class="title-xl m-b-2 text-darkblue">Selection & Evaluation Process</h2>
                               
                                <ul class="l-sub-list">
                                    <li>The innovation Center intends to provide the IEP program open to all those who are interested to join.  </li>
                                    <li>However, due to the large number of applications we receive each year, and to provide quality training, it is difficult to cater all needs at the current stage. Therefore, the initial selection process will depend on the number of applications we receive each cycle and the quality of ideas received. </li>
                                    <li>Applications will be evaluated based on predetermined criteria, including: <strong> Novelty, Value Creation & Impact, Feasibility, Relevancy, and Capability. </strong></li>
                                    <li>If you don’t get accepted at your initial submission cycle, please feel free to apply again for the next cycle. We are open also to meet to discuss your application to prepare you better for the next cycle.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="m-wrap" data-appear-top-offset="-200">
            <div className="services-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-23">
                            <div class="mb-5">
                                <h2 class="title-xl m-b-2 text-darkblue">Program Timeline</h2>
                                <p>Education City Innovative Entrepreneurship Program is designed as an 18-month program that will be conducted annually.</p>
                                <div className="accordion">
                                    {AccordionData.map(({title, content}) => (
                                    <Accordion title={title} content={content}/>
                                    ))}
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-md-23 col-md-offset-2">
                            <h2 class="title-xl m-b-2 text-darkblue">Program Winners</h2>
                            <p>We proudly recognize the outstanding performance of our Program Winners! These individuals have demonstrated exceptional talent, dedication, and hard work in their respective fields, and have achieved remarkable success.</p>
                            <a href="/winner/HBKUINW" rel="noreferrer" class="action m-t-0">2022 Winners</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="m-wrap bg-gray6" data-appear-top-offset="-200">
            <div className="services-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-48">
                            <div class="mb-5">
                                <h2 class="title-xl m-b-2 text-darkblue">FAQs</h2>
                                <div className="accordion">
                                    {FAQData.map(({title, content}) => (
                                    <Accordion title={title} content={content}/>
                                    ))}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default ECIEP;
