export const TeamItems = [
    {
        title: 'Dr. Nadir Yildirim',
        image: '/images/Nadir-Yildirim-thum.png',
        designation: 'Executive Director, Office of Innovation and Industrial Relations'
    },
    {
        title: 'Ms. Abeer Salman A A Al-Hammadi',
        image: '/images/profile-thumb.jpg',
        designation: 'Director of Innovation Center and Economic Development, Innovation Center'
    },
    {
        title: 'Ms. Nada Mohd S I Al-Balam',
        image: '/images/profile-thumb.jpg',
        designation: 'Senior Entrepreneurship Specialist, Innovation Center'
    },
    {
        title: 'Ms. Lama Al Junidi',
        image: '/images/profile-thumb.jpg',
        designation: 'Special Projects Manager, Office of Innovation and Industrial Relations '
    },
    {
        title: 'Ms. Faiha A. Al-Qahtani',
        image: '/images/profile-thumb.jpg',
        designation: 'Innovation Funding Manager, Innovation Funds Office'
    },
    {
        title: 'Mr. Zain Tariq',
        image: '/images/profile-thumb.jpg',
        designation: 'Business Analyst (Student Employee), Innovation Funds Office'
    },
    {
        title: 'Mr. Ahmed Ali Al Khanji',
        image: '/images/profile-thumb.jpg',
        designation: 'Stakeholder Relations Manager, R&D Corp.'
    },
    {
        title: 'Mrs. Mahalakshmi Ramakrishnan',
        image: '/images/profile-thumb.jpg',
        designation: 'Senior Business Analyst, R&D Corp'
    },
    {
        title: 'Ms. Aisulu Anarbekova',
        image: '/images/profile-thumb.jpg',
        designation: 'Innovation and Commercialization Specialist, Office of Innovation and Industrial Relations'
    }
    
];

