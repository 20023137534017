import React from "react";
import {Link, useMatch, useResolvedPath} from "react-router-dom";

const MenuItem = ({
                      title = 'link',
                      link = '#',
                      redirect,
                      load,
                      ...props
                  }) => {
    let resolved = useResolvedPath(link);
    let match = useMatch({path: resolved.pathname, end: true});
    if(redirect){
        return(
            
                <li className="nav-item">
                    <a target={'_blank'} className={`nav-link ${match ? 'active':''}`}  href="http://expertise.hbku.edu.qa/"
                    aria-controls="menu-1" aria-selected="false">{title}</a>
                </li>
        )
    }
    if(load){
        return(
            
            <a href={link} key={title} as={link}>
            <li className="nav-item home" key={title}>
                <span style={{display: "block"}} className={`nav-link ${match ? 'active':''}`}
                   aria-controls="menu-1" aria-selected="false">{title}</span>
            </li>
            </a>
        )
    }
    return (
        <Link to={link} key={title} as={link}>
        <li className="nav-item home" key={title}>
            <span style={{display: "block"}} className={`nav-link ${match ? 'active':''}`}
               aria-controls="menu-1" aria-selected="false">{title}</span>
        </li>
        </Link>
    )
}

export default MenuItem;
