const Winners = [
    {
        title:'HBKU Innovation Fund Cycle 6 Winners Announcement',
        summary: '',
        content : `<section class="m-wrap ">
            <div class="container">
                <div class="row">
                    <div class="col-md-48">
                        <div class="row">
                            <div class="col-md-48">
                                <h2 class="title-xl m-b-2 text-darkblue">HBKU’s Office of Innovation and Industrial Relations has named 12 winners of the Innovation Funds in its sixth cycle.</h2>
                                <p>Awarded under each of the two programs within the HBKU Innovation Funds Office, the winners will receive support to transform their novel ideas into applied solutions.</p>
                                <p> The awardees are: </p>
                                <img class="" src="https://www.hbku.edu.qa/sites/default/files/innovation-funds-cycle-6-winner-announcemt.png" alt="Office of Innovation and Industrial Relations">
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>`,
        image : 'https://www.hbku.edu.qa/sites/default/files/thumb-winner.png',
        id:'HBKUINW'
    },
]


export default Winners
