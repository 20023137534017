import React, {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {changeTitle, changeTitleSEO, changeSubmenu, generalSelector} from "../store/reducers/generalReducer";
import {TeamItems} from '../data/team-items';
import {useSSE} from "use-sse";

const InnovationPage  = () => {


    const generalSettings = useSelector(generalSelector);
    const {title} = generalSettings;
    const dispatch = useDispatch();

    useSSE(()=>{
        dispatch(changeTitle('Innovation at HBKU'))
        dispatch(changeTitleSEO('Office of Innovation and Industrial Relations |'))
        dispatch(changeSubmenu(''))
        return Promise.resolve('Office of Innovation and Industrial Relations |')
    },[])

    useEffect(()=>{
        document.title = 'Office of Innovation and Industrial Relations |'

    },[])

    return (
        <>
            <section className="m-wrap p-b-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-48 col-md-48">
                            <div className="m-b-2">
                                <div className="big">
                                    <h2 className="title-xl m-b-2 text-darkblue">The Office of Innovation and Industrial Relations</h2>
                                    <p>Innovation is at the core of Hamad Bin Khalifa University (HBKU), as it hosts the development of a research and development focused, dynamic, game-changing entrepreneurial ecosystem and a value-added social structure. This setup promotes creating meaningfully-unique solutions for real-life challenges with high commercialization potential under sustainable internal and external collaborations.</p>
                                    <p>As a home-grown, innovation-based, entrepreneurial university, HBKU has tailored a bespoke innovation pipeline to produce cutting-edge technologies in line with Qatar’s national vision. This reinforces and fosters sustainable innovation and an entrepreneurship-based ecosystem through multifaceted education, training, courses, mentorship, and diversified funds for the community.</p>
                                    <p>Office of Innovation and Industrial Relations (OIIR) pioneer this approach and serves Qatar ecosystem through its innovative strategic initiatives HBKU Innovation Center,  HBKU Innovation Funds Office, and HBKU Research and Development Corporation QSTP-LLC. (HBKU R&D Corp.).</p>
                                </div>
                                <div className="profileThumbBlock">
                                    <h2 className="title-xl m-b-2 text-darkblue">Our Team</h2>
                                    <div className="grid-2col">
                                        {TeamItems.map(item => (
                                            <div className="cl2019quote__person">
                                            <figure className="cl2019quote__person--img">
                                                <img className="img-circle" src={item.image}/>
                                            </figure>
                                            <div className="cl2019quote__person__info">
                                                <h5 className="person-name">{item.title}</h5>
                                                <h6 className="person-designation">{item.designation}</h6>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr />
            <section className="">
                <div className="container">
                    <div className="row">
                        <div className="col-md-24">
                        <h2 className="title-xl m-b-2 text-darkblue">Vision</h2>
                        <p>What We Envision For You Strengthen the sustainable innovation and entrepreneurship ecosystem both within and outside HBKU.</p>
                        </div>
                        <div className="col-md-24">
                        <h2 className="title-xl m-b-2 text-darkblue">Mission</h2>
                        <p>The design, development, and implementation of mechanisms required for acceleration of innovative entrepreneurship and intellectual knowledge and technology transfer between university, industry, and government.</p>
                        </div>
                    </div>
                </div>
            </section>
            <hr />
            <section className="s-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-24">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1165.2894540726343!2d51.43312803981748!3d25.313521848358082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45dbf976b81543%3A0x36123da4ecbad70b!2sQF%20Villa%203%20%26%204!5e0!3m2!1sen!2sqa!4v1695918941494!5m2!1sen!2sqa" style={{border:'0', width: '100%', minHeight:'350px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-md-24">
                            <div className=" contactus-block border m-b-3">
                                <div className="card-body"><h5 className="card-title">Innovation at HBKU</h5>
                                    <ul>
                                        <li><span> <i className="fa fa-envelope-o"></i> </span> <a
                                            href="mailto:innovation@hbku.edu.qa ">innovation@hbku.edu.qa </a></li>
                                        <li><span><i className="fa fa-map-marker"></i> </span> P.O. Box: 34110,
                                            Education City, Doha, Qatar
                                        </li>
                                        <li><span><i className="fa fa-hand-o-up"></i> </span> <a
                                            href="https://hbku.wufoo.com/forms/kmoeqt01h3o402/" target="_blank"> Apply
                                            Now</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default InnovationPage;
