import React from "react";
import Menu from "./Menu";
import Footer from "./Footer";
import {Link} from "react-router-dom";
import MailingListBlock from "../blocks/MailingListBlock";
import {generalSelector} from "../store/reducers/generalReducer";
import {useSelector} from 'react-redux';


const Container = (props) => {
    const fontSize24 = {
        fontSize: 24
    }

    const {title} = useSelector(generalSelector);
    const {submenu} = useSelector(generalSelector);
    
    return (
        <div className="innovation-center wrapper">
            <header dir="ltr">
                <div className="top-header">
                    <div className="container">
                        <div className="top-header__inner">
                            <a href="https://www.hbku.edu.qa/en" target="_blank" className="logo-link"><img className="logo" src="/images/HBKU-RGB-2021.png"
                                                                   alt="HBKU logo"/></a>
                            <div className="navigation-top">
                                <div className="navigation-top-application">
                                    <button className="btn d-inline-block d-md-none ntaToggle"><i
                                        className="fa fa-bars"></i></button>
                                    <div className="d-md-none right-sidebar nta-right-sidebar closed">
                                        <button className="btn-close"><i className="fa fa-times"></i></button>
                                        
                                        {/* <a href="https://hbkuqa.elluciancrmrecruit.com/Apply/Account/Login?ReturnUrl=%2fApply"
                                           target="_blank" rel="noreferrer" className="bordred">Apply now</a> */}
                                           <a href="https://www.hbku.edu.qa/ar" target="_blank" rel="noreferrer"
                                        className="nav-link nav-language">العربية</a>
                                       
                                             <a href="https://www.hbku.edu.qa/en/search" target="_blank" className="nav-link nav-language"><i
                                            className="fa fa-search text-darkblue" style={fontSize24}></i></a>
                                    </div>
                                    <div className="d-none d-md-flex">
                                        
                                        {/* <a href="https://hbkuqa.elluciancrmrecruit.com/Apply/Account/Login?ReturnUrl=%2fApply"
                                           target="_blank" rel="noreferrer" className="bordred">Apply now</a> */}
                                           <a href="https://www.hbku.edu.qa/ar" target="_blank" rel="noreferrer"
                                        className="nav-link nav-language">العربية</a>
                                         
                                            <a href="https://www.hbku.edu.qa/en/search" target="_blank" className="nav-link nav-language"><i
                                            className="fa fa-search text-darkblue" style={fontSize24}></i></a>
                                    </div>

                                </div>
                                <div className="navigation-top-nav">
                                    <button className="btn d-inline-block d-md-none ntnToggle"><i
                                        className="fa fa-bars"></i></button>
                                    <div className="d-md-none right-sidebar closed">
                                        <button className="btn-close"><i
                                            className="fa fa-times"></i></button>
                                        <a aria-current="page" className="active"
                                           href="https://www.hbku.edu.qa/en"><img src="/images/home.png" alt="home page"
                                                                           className="d-sm-none d-xs-none"/><span
                                            className="d-md-none ">Home </span></a><a
                                        href="https://www.hbku.edu.qa/en/about-hamad-bin-khalifa-university">About
                                        HBKU</a>
                                        <a href="https://www.hbku.edu.qa/en/academics">Academics</a>
                                        <a href="https://research.hbku.edu.qa">Research</a>
                                        <a href="https://innovation.hbku.edu.qa">Innovation</a>
                                        <a
                                        href="https://www.hbku.edu.qa/en/life-hbku">University Life</a>
                                    </div>
                                    <div className="d-none d-md-flex">
                                        <a href="https://www.hbku.edu.qa/en" target="_blank" rel="noreferrer"><img
                                            src="/images/home.png" alt="home page"/></a>
                                        <a
                                        href="https://www.hbku.edu.qa/en/about-hamad-bin-khalifa-university"
                                        target="_blank"
                                        rel="noreferrer">About HBKU</a>
                                        <a href="https://www.hbku.edu.qa/en/academics"
                                                                          target="_blank" rel="noreferrer">Academics</a>

                                        <a
                                        href="https://research.hbku.edu.qa">Research</a>
                                        <a className={'active'} href="https://innovation.hbku.edu.qa"
                                           target="_blank" rel="noreferrer">Innovation</a>
                                        
                                        <a href="https://www.hbku.edu.qa/en/life-hbku"
                                                                          target="_blank" rel="noreferrer">University
                                        Life</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-hero">
                    <div className="container">
                        <ul className="breadcrumb">
                            <Link to={'/'}>
                                <li><a href="#">Home</a></li>
                            </Link>
                        </ul>
                        <h1>{title}</h1>
                    </div>
                </div>

            </header>
            <div className="menu-tab menu-tab_v3">
                <Menu/>
            </div>
            
                    { (submenu.length >= 1) ? (
                        <div className='header-intern'>
                        <div className='container'>
                        {submenu.map((menuItem, k) => {
                                let activeClass = '';
                                if(menuItem.isActive){
                                activeClass = 'active';
                                }
                                return (
                                <>
                                <Link to={menuItem.link} className={activeClass} >
                                    {menuItem.text}
                                </Link>
                                </>
                                )
                            })}

                            </div>
                        </div>
                        ): ('')
                    }
                    
                
            {props.children}
            <MailingListBlock/>
            <Footer/>
        </div>
    );


};


export default Container;
