const FundingItems = [
    {
        title: 'Innovative Start-Up Fund (ISF)',
        id: '/innovation-center/isf',
        image: '/images/fund-img2.png',
        summary: 'The HBKU Innovative Start-Up Fund (ISF) aims to support HBKU entrepreneurs with industry-driven innovations, targeted at increasing the local capacity, responsiveness, and private enterprise.'
    },
    {
        title: 'Entrepreneurship Program Fund (EPF)',
        id: '/innovation-center/epf',
        image: '/images/fund-img5.png',
        summary: 'The HBKU Entrepreneurship Program Fund (EPF) aims to support Qatar Foundation (QF) entrepreneurs with industry-driven innovations, and to increase local capacity, responsiveness, and private enterprise.'
    },
    {
        title: 'Co-working Space',
        id: '/innovation-center/cws',
        image: '/images/fund-img3.png',
        summary: 'The HBKU Entrepreneurship Program Fund (EPF) aims to support Qatar Foundation (QF) entrepreneurs with industry-driven innovations, and to increase local capacity, responsiveness, and private enterprise.'
    },
    {
        title: 'Workshops',
        id: '/innovation-center/workshops',
        image: '/images/fund-img4.png',
        summary: 'The HBKU Entrepreneurship Program Fund (EPF) aims to support Qatar Foundation (QF) entrepreneurs with industry-driven innovations, and to increase local capacity, responsiveness, and private enterprise.'
    }
];


export default FundingItems;
