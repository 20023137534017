const BenefitsItems = [
    {
        title: 'Capacity Building',
        id: 'javascript:void(0)',
        image: '/images/benefits-img3.png',
        summary: 'Entrepreneurs who pass the evaluation will receive capacity building training workshops that aim to equip participants with the necessary skills, knowledge, and tools to effectively develop and execute their innovative business ideas. Training topics including but not limited to: innovative entrepreneurship, systematic innovation, leadership, project writing, idea pitching, business model and business plan preparations.',
    },
    {
        title: 'Co-working Space',
        id: '/innovation-center/cws',
        image: '/images/benefits-img.png',
        summary: 'Participants will be able to benefit from the co-working space available at HBKU Innovation Center.'
    },
    {
        title: 'Entrepreneurship Program Fund (EPF)',
        id: '/innovation-center/epf',
        image: '/images/benefits-img2.png',
        summary: 'While the entrepreneurs are taking capacity building trainings, they will prepare business plans to apply for the Entrepreneurship Program Fund (EPF) offered by HBKU Innovation Center. This fund is targeted to grant five projects with budgets up to QAR 100,000 for each.'
       
    },
    {
        title: 'Demo Day',
        id: 'javascript:void(0)',
        image: '/images/benefits-img1.png',
        summary: 'The HBKU Innovation Center in partnership with QDB will organize a ‘Demo Day’ in Qatar where EPF awarded entrepreneurs will present their ideas to investors in a chance to raise investment funds up to QAR 250,000 for each to scale up their businesses.'
    },
    {
        title: 'Certification',
        id: 'javascript:void(0)',
        image: '/images/certificate.jpg',
        summary: 'Program participants who have completed capacity building workshops will be presented with a certificate about their participation in the program.'
    }
];


export default BenefitsItems;
