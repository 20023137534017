const Workshops = [
    {
        title:'Systematic Innovation and Leadership',
        summary: '',
        content : `<section class="m-wrap ">
            <div class="container">
                <div class="row">
                    <div class="col-md-48">
                        <div class="row">
                            <div class="col-md-32">
                                <h2 class="title-xl m-b-2 text-darkblue">Systematic Innovation and Leadership</h2>
                                <p>This workshop will provide theoretical and practical knowledge on how to achieve innovation from a cradle-to-grave approach and the basics of leadership. Participants completing this program successfully will understand leadership in detail and be capable of leading idea conversions to innovative products, technologies, and services along with being able to commercialize them.</p>
                                <p>If you would like to get more information about our workshops, please contact us.</p>

                                <a class="l-btn l-btn-db l-btn--darkblue m-b-1" target="_blank"
                                   href="https://hbku.wufoo.com/forms/kmoeqt01h3o402/"> Register here <i
                                    class="fa fa-arrow-right" aria-hidden="true"></i></a>
                            </div>
                            <div class="col-md-14 col-md-offset-2">
                                <div class="l-article">
                                    <div class="video-wrap">
                                        <img class="" src="/images/img-sil.png" alt="Office of Innovation and Industrial Relations">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>`,
        image : '/images/img-sil.png',
        id:'SIL'
    },
    {
        title:'Systematic Innovation and Entrepreneurship',
        summary: '',
        content : `<section class="m-wrap ">
            <div class="container">
                <div class="row">
                    <div class="col-md-48">
                        <div class="row">
                            <div class="col-md-32">
                                <h2 class="title-xl m-b-2 text-darkblue">Systematic Innovation and Entrepreneurship</h2>
                                <p>This workshop will provide theoretical and practical knowledge on how to achieve innovation from a cradle-to-grave approach and the basics of entrepreneurship. Participants completing this program successfully will be able to convert their ideas into innovative products, technologies, services and be able to commercialize them through their startups.</p>
                                
                                <a class="l-btn l-btn-db l-btn--darkblue m-b-1" target="_blank"
                                   href="https://hbku.wufoo.com/forms/kmoeqt01h3o402/"> Register here <i
                                    class="fa fa-arrow-right" aria-hidden="true"></i></a>
                            </div>
                            <div class="col-md-14 col-md-offset-2">
                                <div class="l-article">
                                    <div class="video-wrap">
                                        <img class="" src="/images/img-sie.png" alt="Office of Innovation and Industrial Relations">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>`,
        image : '/images/img-sie.png',
        id: 'SIE'
    },
    {
        title:'Effective Presentation Techniques',
        summary: '',
        content : `<section class="m-wrap ">
            <div class="container">
                <div class="row">
                    <div class="col-md-48">
                        <div class="row">
                            <div class="col-md-32">
                                <h2 class="title-xl m-b-2 text-darkblue">Effective Presentation Techniques</h2>
                                <p>This workshop will provide theoretical and practical knowledge on how to use PowerPoint effectively to design strong presentations, the basics of content preparation, rules of design and pitching. Participants who complete this program successfully will be able to pitch their ideas to public and corporate forums.</p>
                                <a class="l-btn l-btn-db l-btn--darkblue m-b-1" target="_blank"
                                   href="https://hbku.wufoo.com/forms/kmoeqt01h3o402/">Register here <i
                                    class="fa fa-arrow-right" aria-hidden="true"></i></a>
                            </div>
                            <div class="col-md-14 col-md-offset-2">
                                <div class="l-article">
                                    <div class="video-wrap">
                                        <img class="" src="/images/img-ept.png" alt="Office of Innovation and Industrial Relations">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>`,
        image : '/images/img-ept.png',
        id: 'EPT'
    },
    {
        title:'Project Writing',
        summary: '',
        content : `<section class="m-wrap ">
            <div class="container">
                <div class="row">
                    <div class="col-md-48">
                        <div class="row">
                            <div class="col-md-32">
                                <h2 class="title-xl m-b-2 text-darkblue">Project Writing</h2>
                                <p>This workshop will provide theoretical and practical knowledge on how to write a project from start to finish.</p>

                                <a class="l-btn l-btn-db l-btn--darkblue m-b-1" target="_blank"
                                   href="https://hbku.wufoo.com/forms/kmoeqt01h3o402/"> Register here <i
                                    class="fa fa-arrow-right" aria-hidden="true"></i></a>
                            </div>
                            <div class="col-md-14 col-md-offset-2">
                                <div class="l-article">
                                    <div class="video-wrap">
                                        <img class="" src="/images/img-pw.png" alt="Office of Innovation and Industrial Relations">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>`,
        image : '/images/img-pw.png',
        id:'PW'
    },
    {
        title:'Entrepreneurship 101',
        summary: '',
        content : `<section class="m-wrap ">
            <div class="container">
                <div class="row">
                    <div class="col-md-48">
                        <div class="row">
                            <div class="col-md-32">
                                <h2 class="title-xl m-b-2 text-darkblue">Entrepreneurship 101</h2>
                                <p>This workshop will provide theoretical and practical knowledge on the basics of entrepreneurship. Participants who complete this program successfully will be capable of converting their ideas to products, technologies, and services and will be able to commercialize them through their startups.</p>
                                
                                <a class="l-btn l-btn-db l-btn--darkblue m-b-1" target="_blank"
                                   href="https://hbku.wufoo.com/forms/kmoeqt01h3o402/"> Register here <i
                                    class="fa fa-arrow-right" aria-hidden="true"></i></a>
                            </div>
                            <div class="col-md-14 col-md-offset-2">
                                <div class="l-article">
                                    <div class="video-wrap">
                                        <img class="" src="/images/img-e101.png"
                                             alt="Office of Innovation and Industrial Relations">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>`,
        image : '/images/img-e101.png',
        id:'E101'
    },
    {
        title:'Intellectual Property 101',
        summary: '',
        content : `<section class="m-wrap ">
            <div class="container">
                <div class="row">
                    <div class="col-md-48">
                        <div class="row">
                            <div class="col-md-32">
                                <h2 class="title-xl m-b-2 text-darkblue">Intellectual Property 101</h2>
                                <p>This workshop will cover necessary Intellectual Property fundamentals for entrepreneurs.</p>
                               
                                <a class="l-btn l-btn-db l-btn--darkblue m-b-1" target="_blank"
                                   href="https://hbku.wufoo.com/forms/kmoeqt01h3o402/"> Register here <i
                                    class="fa fa-arrow-right" aria-hidden="true"></i></a>
                            </div>
                            <div class="col-md-14 col-md-offset-2">
                                <div class="l-article">
                                    <div class="video-wrap">
                                        <img class="" src="/images/img-el.png" alt="Office of Innovation and Industrial Relations">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>`,
        image : '/images/img-el.png',
        id:'IL'
    },
]


export default Workshops
