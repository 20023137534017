import React from "react";

import Container from "./components/Container";

import {Route, Routes} from "react-router-dom";
import InnovationPage from "./pages/InnovationPage";
import InnovationCenter from "./pages/InnovationCenter";
import FundsPage from "./pages/FundsPage";
import PortfolioPage from "./pages/PortfolioPage";
import FundPage from "./pages/FundPage";
import ProjectPage from "./pages/ProjectPage";
import WorkshopsPage from "./pages/WorkshopsPage";
import WorkshopPage from "./pages/WorkshopPage";
import Eciep from "./pages/EC-IEP";
import InnovativeStartupFund from "./pages/Innovative-Startup-Fund";
import WinnersPage from "./pages/WinnersPage";
import WinnerPage from "./pages/WinnerPage";
import TechnologiesPage from "./pages/TechnologiesPage";
import ApplyForFundingPage from "./pages/ApplyForFundingPage";
import ScrollToTop from "./components/ScrollToTop";
import NewsList from "./pages/NewsList";
import EntrepreneurshipProgramFund from "./pages/EntrepreneurshipProgramFund";
import CoWorkingSpace from "./pages/CoWorkingSpace";
import Entrepreneurship101 from "./pages/Entrepreneurship101";

const  App = () => {

    return (
        <Container>
            <ScrollToTop/>
            <Routes>
                <Route exact path="/" element={<InnovationPage/>}/>
                <Route path="/innovation-center" element={<InnovationCenter/>} />
                <Route path="/funds" element={<FundsPage/>} />
                <Route path="/fund/:id" element={<FundPage/>} />
                <Route path="/project/:id" element={<ProjectPage/>} />
                <Route path="/portfolio" element={<PortfolioPage/>}/>
                <Route path="/innovation-center/eciep" element={<Eciep/>}/>
                <Route path="/innovation-center/isf" element={<InnovativeStartupFund/>}/>
                <Route path="/innovation-center/epf" element={<EntrepreneurshipProgramFund/>}/>
                <Route path="/innovation-center/cws" element={<CoWorkingSpace/>}/>
                <Route path="/innovation-center/1cs" element={<Entrepreneurship101/>}/>
                <Route path="/innovation-center/workshops" element={<WorkshopsPage/>}/>
                <Route path="/workshop/:id" element={<WorkshopPage/>}/>
                <Route path="/winners" element={<WinnersPage />}/>
                <Route path="/winner/:id" element={<WinnerPage/>}/>
                <Route path="/rd-corp" element={<TechnologiesPage/>}/>
                <Route path="/news-announcements" element={<NewsList />}/>
                <Route path="/apply" element={<ApplyForFundingPage/>}/>
            </Routes>
        </Container>
    );
}

export default App;
