import React, {useEffect} from "react";

import InnovationBlock from "../blocks/Innovation/InnovationBlock";
import {useDispatch, useSelector} from "react-redux";
import {changeTitle, changeTitleSEO, changeSubmenu, generalSelector} from "../store/reducers/generalReducer";
import {useSSE} from "use-sse";

const InnovationCenter = ()=>{

    const generalSettings = useSelector(generalSelector);
    const {title} = generalSettings;

    const dispatch = useDispatch()

    useSSE(()=>{
        dispatch(changeTitle('Innovation at HBKU'))
        dispatch(changeTitleSEO('Office of Innovation and Industrial Relations | Innovation Center'))
        dispatch(changeSubmenu([
            { text: 'EC Innovative Entrepreneurship Program', link: '/innovation-center/eciep', isActive: false},
            { text: 'Innovative Start-Up Fund', link: '/innovation-center/isf', isActive: false},
            { text: 'Entrepreneurship Program Fund', link: '/innovation-center/epf', isActive: false},
            { text: 'Co-working Space', link: '/innovation-center/cws', isActive: false},
            { text: '1-to-1 Consultation Sessions', link: '/innovation-center/1cs', isActive: false},
            { text: 'workshops', link: '/innovation-center/workshops', isActive: false},
        ]))
        return Promise.resolve('Office of Innovation and Industrial Relations - Innovation Center');
    },[])
    useEffect(()=>{
        document.title = 'Office of Innovation and Industrial Relations | Innovation Center'
    },[]);

    return(

        <InnovationBlock />
    )
}

export default InnovationCenter;
