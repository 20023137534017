import React, {useEffect} from "react";

import Winners from "../data/winners";
import Winner from "../components/Winner";
import {useDispatch, useSelector} from "react-redux";
import {changeTitle, changeTitleSEO, changeSubmenu, generalSelector} from "../store/reducers/generalReducer";
import {useSSE} from "use-sse";

const WinnersPage = ({
    winners = Winners
})=>{

    const generalSettings = useSelector(generalSelector);
    const {title} = generalSettings;
    const dispatch = useDispatch()

    useSSE(()=>{
        dispatch(changeTitle('Innovation at HBKU'))
        dispatch(changeTitleSEO('Office of Innovation and Industrial Relations | HBKU Innovation Fund Cycle 6 Winners Announcement '))
        dispatch(changeSubmenu(''))
        return Promise.resolve('Office of Innovation and Industrial Relations | HBKU Innovation Fund Cycle 6 Winners Announcement ')
    },[])

    useEffect(()=>{
        document.title = 'Office of Innovation and Industrial Relations | HBKU Innovation Fund Cycle 6 Winners Announcement '
    },[])
    return (
        <section className="m-wrap bg-gray6 " id="section4">
            <div className="services-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-48">
                            <div className="title--info">
                                <h2 className="title-xl m-b-2 text-darkblue ">Winners Announcements  </h2>
                            </div>
                            <div className="row row-eq-height">
                                {winners.map(
                                    (item,i)=> (
                                        <Winner {...item} key={'winner-'+i} />
                                    )
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WinnersPage;
