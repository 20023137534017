const AccordionData = [
 {
   title: '2023',
   content : [
     {
         date: 'February 1, 2023',
         contentTitle: 'Program Kick-off'
     },
     {
         date: 'April 8, 2023',
         contentTitle: 'Application Deadline'
     },
     {
         date: 'May, 2023',
         contentTitle: 'Evaluations of Applications'
     },
     {
         date: 'June, 2023',
         contentTitle: 'Program Winner Annoucements'
     },
     {
         date: 'End August to Mid Oct 2023',
         contentTitle: 'Capacity Building Workshops & Events'
     },
     {
         date: 'November, 2023',
         contentTitle: 'Found Winner Annoucements'
     }
   ]
 },
 {
 title: '2024',
 content : [
     {
       date: 'November 2023 - August 2024',
       contentTitle: 'Project Management and Mentorship'
     },
     {
       date: 'October 30, 2023',
       contentTitle: 'Enterpreneurship Program Fund Application Deadline'
     }
 ]
 }
];

export default AccordionData;