import React, {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {changeTitle, changeTitleSEO, changeSubmenu, generalSelector} from "../store/reducers/generalReducer";
import {useSSE} from "use-sse";
const NewsList = () => {
    const generalSettings = useSelector(generalSelector);
    const {title} = generalSettings;
    const dispatch = useDispatch()

    useSSE(()=>{
        dispatch(changeTitle('Innovation at HBKU'))
        dispatch(changeTitleSEO('Office of Innovation and Industrial Relations | Licensing Portfolio'))
        dispatch(changeSubmenu(''))
        return Promise.resolve('Office of Innovation and Industrial Relations | Licensing Portfolio');
    },[])

    useEffect(() => {
        document.title = 'Office of Innovation and Industrial Relations | Licensing Portfolio'
    }, []);
    
    const [error, setError] = useState(null);
    const [isLoadedNews, setIsLoadedNews] = useState(false);
    const [newsItems, setNewsItems] = useState([]);
    const [page, setPage] = useState(0);
    console.log(newsItems)
    useEffect(() => {
        loadMore()
    }, []);

    const loadMore = () => {
        return(
            fetch('https://www.hbku.edu.qa/en/api/ri-news.json?field_news_category_tid=381&language=en&page='+page)
            .then(res => res.json())
            .then((newslist) => {
                setIsLoadedNews(true)
                setNewsItems([...newsItems,...newslist])
                setPage(newslist.length ? page + 1 : page)
                },
                (error) => {
                    setIsLoadedNews(true)
                    setError(error)
                }
            )
        )
    }

    if(error){
        return <div>Error: {error.message}</div>
    }else if(!isLoadedNews){
        return <div>Loading...</div>
    }else{
        return (
            <section className="m-wrap">
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-48">
                            <div className="t-block">
                                <div className="t-block__content ">
                                    {newsItems.map((item, i) => {
                                        return(
                                            <div key={i} className="t-block_list newsblocklist--list slow-up-down">
                                                <a target="_blank" href={'https://www.hbku.edu.qa/en/node/'+item.nid}>
                                                    <div className="t-block__list--image">
                                                        <figure className="ripple-effect overlay">
                                                            <span className="hbku-tags custom-badge-cont m-b-0">
                                                                <span className="custom-badge me-2">Innovation</span>
                                                            </span>
                                                            <img width={800} height={400} src={item.Image} alt="" className="full-width" />
                                                        </figure>
                                                    </div>
                                                    <div className="t-block__list__info">
                                                        <div className="t-block__list--title">
                                                            <h4 className="item--title">{item.title}</h4>
                                                        </div>
                                                        <p className="t-block__list--content">{item.summary}</p>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    })}
                                    
                                </div>
                            </div>
                            <div className='text-center'><button  onClick={()=>loadMore()} className='action'>Load more</button></div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    
}


export default NewsList;